import React from 'react';

import { ReactComponent as LoaderImg } from '../../../../public/icons/loaderAnimation.svg';
import Button from '../../atoms/Button/Button';
import { TNewsCardProps } from '../../atoms/NewsCard';
import { TextNews } from '../../atoms/TextNews';
import { Container } from '../../sections/Container';

type TTextsNews = {
  newsItems: TNewsCardProps[];
  isLoading?: boolean;
  title: string;
  buttons?: React.ComponentProps<typeof Button>[];
};

export const TextsNews: React.FC<TTextsNews> = ({
  newsItems,
  isLoading,
  title,
  buttons,
}) => {
  return (
    <section>
      <Container>
        <div className='flex flex-col gap-8 lg:flex-row lg:gap-4'>
          {title && (
            <h3 className='w-full text-5xl font-bold leading-relaxed text-interface-1000 lg:max-w-[200px] xl:max-w-[296px] xl:text-8xl'>
              {title}
            </h3>
          )}
          <div className='flex w-full max-w-full flex-col gap-6 overflow-hidden'>
            <div className='flex flex-col'>
              {isLoading ? (
                <div className='size-full bg-surface-100'>
                  <LoaderImg className='h-[708px] w-full md:h-[477px] lg:h-[571px] xl:h-[505px]' />
                </div>
              ) : (
                newsItems.map((newsItem, index) => (
                  <TextNews key={index} {...newsItem} />
                ))
              )}
            </div>
            {buttons && buttons.length !== 0 && (
              <div className='flex flex-row items-start gap-2.5 opacity-50'>
                {buttons.map((button, index) => (
                  <Button key={index} {...button} className='h-auto'>
                    {button.children}
                  </Button>
                ))}
              </div>
            )}
          </div>
        </div>
      </Container>
    </section>
  );
};
