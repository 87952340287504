import { cva } from 'cva';
import Link from 'next/link';
import React from 'react';

import { EB2BrokerNewsProductType } from './TextNews.constants';
import Button from '../Button/Button';
import { EButtonSize, EButtonTheme, EButtonVariant } from '../Button';
import { TNewsCardProps } from '../NewsCard';

export const TextNews: React.FC<TNewsCardProps> = props => {
  const { title, description, link, tags, productType } = props;

  const renderLink = link.href;
  const tag = (tags && tags[0]) || undefined;

  if (!renderLink) {
    return null;
  }

  const gradientStyles = cva(
    'line-clamp-2 bg-interface-1000 bg-clip-text text-transparent md:me-2 md:inline',
    {
      variants: {
        productType: {
          [EB2BrokerNewsProductType.B2BinPay]:
            'bg-[linear-gradient(90deg,_#F44429_0%,_#000000_32.57%)] rtl:bg-[linear-gradient(270deg,_#F44429_0%,_#000000_32.57%)]',
          [EB2BrokerNewsProductType.B2Core]:
            'bg-[linear-gradient(90deg,_#1073FF_0%,_#000000_32.57%)] rtl:bg-[linear-gradient(270deg,_#1073FF_0%,_#000000_32.57%)]',
          [EB2BrokerNewsProductType.B2Trader]:
            'bg-[linear-gradient(90deg,_#07D6A5_0%,_#000000_32.57%)] rtl:bg-[linear-gradient(270deg,_#07D6A5_0%,_#000000_32.57%)]',
          [EB2BrokerNewsProductType.MarksMan]:
            'bg-[linear-gradient(90deg,_#4F8BE3_0%,_#000000_32.57%)] rtl:bg-[linear-gradient(270deg,_#4F8BE3_0%,_#000000_32.57%)]',
          [EB2BrokerNewsProductType.Liquidity]:
            'bg-[linear-gradient(90deg,_#0ABE98_0%,_#000000_32.57%)] rtl:bg-[linear-gradient(270deg,_#0ABE98_0%,_#000000_32.57%)]',
        },
      },
    },
  );

  return (
    <div className='flex flex-col-reverse gap-4 border-b-2 border-surface-200 py-6 first:pt-0 md:flex-row md:gap-2 lg:first:pt-3.5 xl:first:pt-7'>
      <Link
        className='block w-full overflow-hidden text-ellipsis text-lg font-medium leading-normal text-interface-700 md:max-w-full md:whitespace-nowrap'
        {...link}
      >
        <span className={gradientStyles({ productType })}>{title}</span>
        {description ? (
          <span className='hidden md:contents'>{description}</span>
        ) : null}
      </Link>
      {tag && tag.href && (
        <div className='opacity-50 prose-a:h-full prose-a:max-w-fit prose-a:justify-start md:w-[184px] md:min-w-[184px] md:prose-a:ms-auto md:prose-a:justify-end md:prose-a:text-end'>
          <Button
            size={EButtonSize.Large}
            variant={EButtonVariant.Text}
            theme={EButtonTheme.Secondary}
            {...tag}
          />
        </div>
      )}
    </div>
  );
};
